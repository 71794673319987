import { AutoAwesome, Edit } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/joy";
import { Handle, Position } from "@xyflow/react";
import React, { useState } from "react";
import { useFlow } from "../FlowContext";

function baseStyle({ theme }) {
  return {
    border: `1.5px solid ${theme.palette.primary[500]}`,
    backgroundColor: theme.colorSchemes.light.palette.background.surface,
    boxShadow: null,
    width: 200,
    height: 70,
  };
}

function hoveredStyle({ theme }) {
  return {
    border: `1.5px solid ${theme.palette.primary[600]}`,
    backgroundColor: theme.palette.primary.plainHoverBg,
    boxShadow: ` 0 1px 4px 1px rgba(0, 0, 0, 0.08)`,
  };
}

function selectedStyle({ theme }) {
  return {
    border: `1.5px solid ${theme.palette.primary[700]}`,
    backgroundColor: theme.palette.primary.plainActiveBg,
    boxShadow: null,
  };
}

export default function BaseNode({ selected, isConnectable, data, id }) {
  const [hovered, setHovered] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const flow = useFlow();
  const style = {
    borderRadius: "8px",

    ...baseStyle({ theme }),
    ...(selected && selectedStyle({ theme })),
    ...(hovered && !selected && hoveredStyle({ theme })),
  };

  function handleEdit(e) {
    e.stopPropagation();
    flow.setDrawerNode(id);
  }

  return (
    <div
      style={style}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {/* <Drawer
        open={selected}
        onClose={() => setDrawerOpen(false)}
        // hideBackdrop={true}
        anchor="right"
        slotProps={{
          backdrop: {
            sx: {
              display: "none",
              backgroundColor: null,
            },
          },
          content: {
            sx: {
              flex: "0 0 auto",
            },
          },
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography level="title-md">Coucou</Typography>
          <ModalClose />
        </Stack>
      </Drawer> */}
      <Handle
        type="target"
        position={Position.Top}
        id="in"
        style={{ background: theme.palette.primary[700] }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="out"
        style={{ background: theme.palette.primary[700] }}
        isConnectable={isConnectable}
      />
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            p: 0,
            pl: 0.5,
            minHeight: 14,
            minWidth: 14,
            height: 14,
            width: 14,
          }}
        >
          <NodeIcon type={data.type} theme={theme} />
        </Stack>
        <Stack direction="row" alignItems="center">
          <IconButton
            sx={{ p: 1.5, minHeight: 14, minWidth: 14, height: 14, width: 14 }}
            onClick={handleEdit}
          >
            <Edit sx={{ fontSize: 12 }} />
          </IconButton>
          {/* <IconButton
            sx={{ p: 1.5, minHeight: 14, minWidth: 14, height: 14, width: 14 }}
          >
            <MoreVert sx={{ fontSize: 12 }} />
          </IconButton> */}
        </Stack>
      </Stack>
      <Stack alignItems="center" justifyContent="center">
        <Typography level="title-md" height="100%">
          {data.label}
        </Typography>
      </Stack>
    </div>
  );
}

export function NodeIcon({ type, theme }) {
  switch (type) {
    case "syncpulseAI":
      return <AutoAwesome sx={{ p: 0, fontSize: 12 }} color="primary" />;
    case "io":
      return (
        <Box
          sx={{
            width: 14,
            height: 14,
            fontFamily: "Impact",
            fontSize: 10,
            color: theme.palette.primary[500], //todo check que c'est 500
          }}
          textAlign="center"
        >
          iO
        </Box>
      );
    default:
      return <Box sx={{ width: 12, height: 12 }} />;
  }
}
