import DocViewer from "@cyntler/react-doc-viewer";
import { Editor } from "@monaco-editor/react";
import { Box } from "@mui/joy";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import * as XLSX from "xlsx";

export default function FileDisplay({ file, writeMode }) {
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const table = useMaterialReactTable({
    columns: tableColumns,
    data: tableData, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  });

  useEffect(() => {
    if (file.type === "text/csv") {
      const parsed = Papa.parse(file.content, { header: true });
      setTableData(parsed.data);
      setTableColumns(
        parsed.meta.fields.map((field) => ({
          accessorKey: field,
          header: field,
        }))
      );
    } else if (
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel"
    ) {
      const workbook = XLSX.read(file.content, { type: "binary" });
      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      const json = XLSX.utils.sheet_to_json(firstSheet);
      const columns = Object.keys(json[0] || {}).map((key) => ({
        accessorKey: key,
        header: key,
      }));
      setTableData(json);
      setTableColumns(columns);
    }
  }, [file]);

  const renderContent = () => {
    switch (true) {
      case file.type === "text/csv":
        return (
          <Editor
            height="100%"
            theme="vs"
            value={file.content}
            options={{
              minimap: { enabled: false },
              scrollbar: { vertical: "hidden" },
            }}
          />
        );
      case file.type === "application/json":
        return (
          <Editor
            height="100%"
            theme="vs"
            language="json"
            value={file.content}
          />
        );
      case file.type === "text/csv" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel":
        return (
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            overflow="hidden"
          >
            <MaterialReactTable
              data={tableData}
              columns={tableColumns}
              enableDensityToggle={false}
              enableColumnActions={true}
              enableColumnFilterModes={true}
              enableColumnFilters={true}
              enableHiding={false}
              initialState={{
                density: "compact",
                showGlobalFilter: true,
              }}
              enablePagination={false}
              muiTableContainerProps={{
                sx: {
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                },
              }}
              muiTableProps={{
                sx: {
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  //   height: "100%",
                },
              }}
              muiTableBodyProps={{
                sx: {
                  height: "100%",
                  overflow: "scroll",
                },
              }}
            />
          </Box>
        );
      case file.type === "text/javascript" ||
        file.type === "application/javascript":
        return (
          <Editor
            height="100%"
            theme="vs"
            language="javascript"
            value={file.content}
          />
        );
      case file.type === "application/pdf":
        return (
          <Document
            file={file.content}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        );
      case file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type === "application/msword":
        return <DocViewer documents={[{ uri: file.content }]} />;
      case file.type.startsWith("text/"):
        return (
          <Editor
            height="100%"
            theme="vs"
            language="plaintext"
            value={file.content}
          />
        );
      default:
        return (
          <Editor
            height="100%"
            theme="vs"
            language="plaintext"
            value={file.content}
          />
        );
    }
  };

  return <Box height="100%">{renderContent()}</Box>;
}
