export async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isApiPending(api) {
  if (api.status === "pending") {
    return true;
  }
}

export function isApiError(api) {
  if (api.status === "rejected") {
    return true;
  }
}

export function isApiFulfilled(api) {
  if (api.status === "fulfilled") {
    return true;
  }
}
