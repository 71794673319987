import {
  Box,
  Card,
  Sheet,
  Stack,
  styled,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  useTheme,
} from "@mui/joy";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Root from "../layout/Root";
import Home from "./Home";

const CustomTab = styled(Tab)(({ theme }) => ({
  "&.MuiTab-root": {
    borderRadius: "10px 10px 0px 0px",
    padding: "0px 12px",
    minHeight: "20px",
    paddingLeft: "16px",
    paddingRight: "16px",
    marginRight: "4px",
    // borderBottom: "none",
  },
  "&:not(.Mui-selected)": {
    backgroundColor: "inherit",
    color: theme.palette.primary[700],
  },
  "&.MuiTab-root:hover": {
    color: "white",
    backgroundColor: theme.palette.primary[400],
  },
  "&.Mui-selected": {
    color: "white",
    backgroundColor: theme.palette.primary[500],
  },
  "&.Mui-selected:hover": {
    backgroundColor: theme.palette.primary[500],
  },
}));
export default function Workspace() {
  const [selectedTab, setSelectedTab] = useState("home");
  const { workspaceId } = useParams();
  const theme = useTheme();
  return (
    <Root>
      <Sheet
        sx={{
          pl: 2,
          pt: 2,
          pb: 2,
          mb: 4,
          width: "100%",
          backgroundColor: theme.palette.background.body,
        }}
        //   variant="outlined"
        //   elevation={2}
      >
        <Typography level="h1" color="neutral" fontWeight={300}>
          Client 1
        </Typography>
      </Sheet>
      <Box pl={2} pr={2}>
        <Tabs
          orientation="horizontal"
          size="sm"
          variant="plain"
          sx={{
            borderBottom: 0,
            backgroundColor: theme.colorSchemes.light.palette.background.level1,
          }}
          defaultValue="home"
        >
          <TabList
            sx={{
              ml: 2,
              borderBottom: 0,
              boxShadow: "none",
            }}
            disableUnderline
          >
            <Stack direction="row" justifyContent="space-between" width="100%">
              <Stack direction="row">
                <CustomTab
                  variant="solid"
                  color="primary"
                  theme={theme}
                  value="home"
                  disableIndicator
                >
                  Home
                </CustomTab>
                {/* <CustomTab
                      variant="solid"
                      color="primary"
                      value="flows"
                      disableIndicator
                    >
                      Flows
                    </CustomTab> */}
                <CustomTab
                  variant="solid"
                  color="primary"
                  value="connections"
                  disableIndicator
                >
                  Connections
                </CustomTab>
              </Stack>
              <Stack direction="row">
                <CustomTab
                  variant="solid"
                  color="primary"
                  value="users"
                  disableIndicator
                >
                  Users
                </CustomTab>
                <CustomTab
                  variant="solid"
                  color="primary"
                  value="files"
                  disableIndicator
                >
                  Files
                </CustomTab>
                <CustomTab
                  variant="solid"
                  color="primary"
                  value="env"
                  disableIndicator
                >
                  Settings
                </CustomTab>
                <CustomTab
                  variant="solid"
                  color="primary"
                  value="admin"
                  disableIndicator
                >
                  Admin
                </CustomTab>
              </Stack>
            </Stack>
          </TabList>
          <TabPanel sx={{ p: 0 }} value="home">
            <Card variant="outlined">
              <Home />
            </Card>
          </TabPanel>
          <TabPanel sx={{ p: 0 }} value="flows">
            <Card variant="outlined">
              <Box>Flows</Box>
            </Card>
          </TabPanel>
        </Tabs>
      </Box>
      {/* <Stack direction="row" justifyContent="space-between" pl={2} pt={1}>
          <Stack direction="row" spacing={1}>
            <Typography level="body-xs">Home</Typography>
            <Typography level="body-xs">Flows</Typography>
            <Typography level="body-xs">Connections</Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Typography level="body-xs">Users</Typography>
            <Typography level="body-xs">Files</Typography>
            <Typography level="body-xs">Env</Typography>

            <Typography level="body-xs">Admin</Typography>
          </Stack>
        </Stack> */}
    </Root>
  );
}
