import { Box, useTheme } from "@mui/joy";
import React, { useState } from "react";
import Chat from "./Chat";
import CodeEditor from "./CodeEditor";

export default function SyncPulseAISetup() {
  const [editMode, setEditMode] = useState("ui");
  const [inTransition, setInTransition] = useState(false);
  const [code, setCode] = useState(demoCode);
  function handleCodeChange(value) {
    setCode(value);
  }
  const theme = useTheme();

  function handleEditMode(newMode) {
    setInTransition(true);
    setEditMode(newMode);
  }

  return (
    <Box
      mt={2}
      borderTop={`1.5px solid ${theme.palette.primary[500]}`}
      height="100%"
      sx={{ backgroundColor: theme.palette.background.level1 }}
      display="flex"
      flexDirection="row"
    >
      <Box
        width={editMode === "ui" ? "100%" : "0px"}
        height="100%"
        // display="flex"
        // flexDirection="column"
        // alignItems={
        //   editMode === "code" && !inTransition ? "center" : "flex-start"
        // }
        sx={{ transition: "width 0.2s" }}
        onTransitionEnd={() => {
          setInTransition(false);
        }}
      >
        {editMode === "ui" && !inTransition && (
          // <TransitionComponent mode={editMode} />
          <Chat inTransition={inTransition} handleEditMode={handleEditMode} />
        )}
      </Box>
      <Box
        height="100%"
        width={editMode === "ui" ? "0px" : "100%"}
        sx={{
          borderLeft: `1.5px solid ${theme.palette.primary[500]}`,
          transition: "width 0.2s",
          // backgroundColor: "#1e1e1e",
        }}
        // display="flex"
        // flexDirection="column"
        // alignItems={
        //   editMode === "ui" && !inTransition ? "center" : "flex-start"
        // }
        onTransitionEnd={() => {
          setInTransition(false);
        }}
      >
        {editMode === "code" && !inTransition && (
          <CodeEditor
            handleEditMode={handleEditMode}
            code={code}
            setCode={setCode}
          />
        )}
      </Box>
    </Box>
  );
}

// function TransitionComponent({ mode }) {
//   return (
//     <div
//       style={{
//         background: "linear-gradient(to right, #primary500, grey)",
//       }}
//     >
//       {mode === "ui" ? "Code" : "AI"}
//     </div>
//   );
// }

const demoCode = `
const inputId = "7807087tuiaytuoay67896phjhl"
const files = input[inputId].files
const file = files[0]
let fileContent = file.content
const rows = fileContent.split('\\n').map(row => row.trim()).filter(row => row !== '');
let headers = rows[0].split('|');
//console.log(headers)
// Step 3: Remove the first 12 rows and the last row
let records = rows.slice(12, -1).map(row => {
  const values = row.split('|');
  let record = {};
  headers.forEach((header, index) => {
    record[header] = values[index];
  });
  return record;
});
// Step 4: Remove unnecessary columns
const columnsToKeep = ["Texte carte", "N° de client", "Nom du client", "Terminal", "Nom du produit", "Quantité"];
records = records.map(record => {
  let newRecord = {};
  columnsToKeep.forEach(col => {
    newRecord[col] = record[col];
  });
  return newRecord;
});

// Step 5: Filter out rows where "Texte carte" is empty
records = records.filter(record => record["Texte carte"] && record["Texte carte"].trim() !== '');

// Step 6: Filter out rows where "Quantité" is zero
records = records.filter(record => parseFloat(record["Quantité"]) !== 0);

// Step 7: Add new columns (station, station2, code article, etc.)
// Adding "station" column based on the formula =STXT(Terminal;11;20)
records.forEach(record => {
  record["station"] = record["Terminal"].substring(10, 30).trim();
});

// Adding "station2" column =SUBSTITUTE(Texte carte;" ";"")
records.forEach(record => {
  record["station2"] = record["Texte carte"].replace(/ /g, '');
});

// Adding "code article" =STXT(station2;1;5)&""&SUBSTITUTE(Nom du produit;" ";"")
records.forEach(record => {
  record["code article"] = record["station2"].substring(0, 5) + record["Nom du produit"].replace(/ /g, '');
});

// Step 8: Convert records back to CSV format
let outputCSV = [columnsToKeep.join('|') + ';station;station2;code article'];
records.forEach(record => {
  let row = columnsToKeep.map(col => record[col]).concat([record["station"], record["station2"], record["code article"]]);
  outputCSV.push(row.join('|'));
});


console.log('CSV file processed and output.csv generated successfully.');


let newFileContent = outputCSV.join('\\n')
console.log(newFileContent)
return {files:[{...file,content:newFileContent}]}`;
const demoCode2 = `
const fs = require('fs');
const csv = require('csv-parser');
const xlsx = require('xlsx');
const path = require('path');

function readCustomerSettings(filePath) {
  return new Promise((resolve, reject) => {
    const customerSettings = {};
    fs.createReadStream(filePath)
      .pipe(csv())
      .on('data', (row) => {
        customerSettings[row['customer_id']] = row['ebp_import_level'];
      })
      .on('end', () => {
        resolve(customerSettings);
      })
      .on('error', reject);
  });
}

function process1FACT1CAMTransactions(inputFile, customerSettings) {
  const transactions = {};
  const workbook = xlsx.readFile(inputFile);
  const sheetName = workbook.SheetNames[0];
  const sheet = workbook.Sheets[sheetName];
  const rows = xlsx.utils.sheet_to_json(sheet);

  rows.forEach((row) => {
    if (!row['N° de client']) return;
    const customerId = String(row['N° de client']);
    if (customerId !== 'nan' && customerId in customerSettings) {
      const level = customerSettings[customerId];
      if (!transactions[level]) transactions[level] = [];

      const stationName = row['Terminal'].replace("-", "").split('DAC ')[1].trim().toUpperCase();
      const numdoc = \`\${customerId}\${stationName.replace(' ', '')}\`;
      const date = new Date(row['Horodateur']).toLocaleDateString('fr-FR');
      const periode = new Date(date).toLocaleDateString('fr-FR', { month: 'short', year: '2-digit' }).replace('01', 'janv').replace('02', 'févr').replace('03', 'mars').replace('04', 'avr').replace('05', 'mai').replace('06', 'juin').replace('07', 'juil').replace('08', 'août').replace('09', 'sept').replace('10', 'oct').replace('11', 'nov').replace('12', 'déc');
      const codeArticle = (stationName.slice(0, 5) + row['Nom du produit']).replace(' ', '').toUpperCase();

      const adaptedRow = {
        'NUMDOC': numdoc.toUpperCase(),
        'DATE': date,
        'PERIODE': periode,
        'Texte carte': '',
        'N° de client': row['N° de client'],
        'Nom du client': row['Nom du client'],
        'STATION': stationName,
        'CODE ARTICLE': codeArticle,
        'Nom du produit': row['Nom du produit'],
        'Quantité': row['Quantité']
      };
      transactions[level].push(adaptedRow);
    }
  });

  return transactions;
}

function writeOutputFiles(transactions) {
  for (const [level, rows] of Object.entries(transactions)) {
    const outputFile = \`1FACT1\${level.slice(0, 3).toUpperCase()}.csv\`;
    const csvWriter = require('csv-writer').createObjectCsvWriter({
      path: outputFile,
      header: [
        { id: 'NUMDOC', title: 'NUMDOC' },
        { id: 'DATE', title: 'DATE' },
        { id: 'PERIODE', title: 'PERIODE' },
        { id: 'Texte carte', title: 'Texte carte' },
        { id: 'N° de client', title: 'N° de client' },
        { id: 'Nom du client', title: 'Nom du client' },
        { id: 'STATION', title: 'STATION' },
        { id: 'CODE ARTICLE', title: 'CODE ARTICLE' },
        { id: 'Nom du produit', title: 'Nom du produit' },
        { id: 'Quantité', title: 'Quantité' }
      ]
    });

    csvWriter.writeRecords(rows).then(() => {
      console.log(\`Output written to \${outputFile}\`);
    });
  }
}
`;

//  {editMode === "code" && !inTransition ? (
//           null
//           <IconButton
//             color="primary"
//             onClick={() => {
//               setEditMode("ui");
//               setInTransition(true);
//             }}
//             sx={{ mt: 0.5 }}
//           >
//             <AutoAwesome />
//           </IconButton>
//         ) : (

// {editMode === "ui" && !inTransition
//           ? null
//           : // <IconButton
//             //   color="primary"
//             //   onClick={() => {
//             //     setEditMode("code");
//             //     setInTransition(true);
//             //   }}
//             //   sx={{ mt: 0.5 }}
//             // >
//             //   <Code />
//             // </IconButton>
