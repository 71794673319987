import { Box, Stack, Typography } from "@mui/joy";
import React from "react";
import { useFlowData } from "../Flow";
import GroupData from "./GroupData";

const files = [
  {
    id: "57685ryuryu578aryuOa780",
    name: "customers.csv",
    type: "text/csv",
    content:
      "id,name,email\n1,John Doe,john.doe@example.com\n2,Jane Doe,jane.doe@example.com",
  },
  {
    id: "gzzuogdbjqsghuehgkjbd",
    name: "customer.json",
    type: "application/json",
    content:
      '{\n  "customers": [\n    {\n      "id": 1,\n      "name": "John Doe",\n      "email": "john.doe@example.com"\n    },\n    {\n      "id": 2,\n      "name": "Jane Doe",\n      "email": "jane.doe@example.com"\n    }\n  ]\n}',
  },
];

export default function Output() {
  const { outputData } = useFlowData();
  return (
    <Stack direction="column" height="100%" width="100%">
      <Typography level="h4" color="neutral" sx={{ fontWeight: 200 }} pl={0.5}>
        OUTPUT
      </Typography>
      <Box height="100%">
        {/* <Tabs variant="plain" defaultValue="sftp" sx={{ height: "100%" }}>
          <TabList sx={{ minHeight: "20px", height: "20px" }}>
            {files.map((file) => (
              <Tab
                disableIndicator
                value={file.id}
                key={file.id}
                sx={{ p: 0, pr: 0.5, pl: 0.5, minBlockSize: "20px" }}
              >
                <Typography level="body-xs">{file.name}</Typography>
              </Tab>
            ))}
          </TabList>
          {files.map((file) => (
            <TabPanel
              value={file.id}
              sx={{ p: 0, height: "100%" }}
              key={file.id}
            >
              <FileDisplay file={file} />
            </TabPanel>
          ))}
        </Tabs> */}
        <GroupData data={outputData} setData={null} />
      </Box>
    </Stack>
  );
}
