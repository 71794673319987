import React, { createContext, useContext, useState } from "react";
const FlowContext = createContext();

export function FlowProvider({ children }) {
  const [drawerNode, setDrawerNode] = useState(null);

  return (
    <FlowContext.Provider value={{ drawerNode, setDrawerNode }}>
      {children}
    </FlowContext.Provider>
  );
}

export function useFlow() {
  return useContext(FlowContext);
}
