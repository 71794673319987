import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  BaseEdge,
  Controls,
  ReactFlow,
} from "@xyflow/react";
import React, { useState } from "react";

import "@xyflow/react/dist/style.css";
import BaseNode from "./BaseNode";

const initialNodes = [
  {
    id: "1",
    type: "baseNode",
    data: { label: "Hubpost connection", type: "syncpulseAI" },
    position: { x: 250, y: 20 },
  },
  {
    id: "2",
    type: "baseNode",
    data: { label: "Transform json -> xml", type: "syncpulseAI" },
    position: { x: 250, y: 220 },
  },
  {
    id: "3",
    type: "baseNode",
    data: { label: "Push to Sage X3", type: "io" },
    position: { x: 250, y: 420 },
  },
];

const initialEdges = [
  {
    id: "e1-2",
    source: "1",
    target: "2",
    style: { stroke: "#8A2BE2", strokeDasharray: "10, 10" },
    // type: "baseEdge",
  },
  {
    id: "e2-3",
    source: "2",
    target: "3",
    style: { stroke: "#8A2BE2", strokeDasharray: "10, 10" },
    // type: "baseEdge",
  },
];

// const initialNodes = [
//   {
//     id: "1",
//     type: "baseNode",
//     data: { label: "WEBFUEL", type: "dataSource" },
//     position: { x: 0, y: 100 },
//   },
//   {
//     id: "2",
//     type: "baseNode",
//     data: { label: "Script de Traitement des Données", type: "process" },
//     position: { x: 200, y: 100 },
//   },
//   {
//     id: "3",
//     type: "baseNode",
//     data: { label: "EBP Gestion Co", type: "billingSoftware" },
//     position: { x: 400, y: 50 },
//   },
//   {
//     id: "4",
//     type: "baseNode",
//     data: { label: "Dossier Spécifique pour ZEENDOC", type: "storage" },
//     position: { x: 600, y: 100 },
//   },
//   {
//     id: "5",
//     type: "baseNode",
//     data: { label: "ZEENDOC Clients", type: "documentManagement" },
//     position: { x: 800, y: 100 },
//   },
// ];

// const initialEdges = [
//   {
//     id: "e1-2",
//     source: "1",
//     target: "2",
//     label: "Rapport de Transactions",
//     type: "smoothstep",
//     style: { stroke: "#8A2BE2" },
//   },
//   {
//     id: "e2-3",
//     source: "2",
//     target: "3",
//     label: "Données Traitées pour Facturation",
//     type: "smoothstep",
//     style: { stroke: "#8A2BE2" },
//   },
//   {
//     id: "e2-4",
//     source: "2",
//     target: "4",
//     label: "Annexes Client",
//     type: "smoothstep",
//     style: { stroke: "#8A2BE2", strokeDasharray: "5,5" },
//   },
//   {
//     id: "e3-4",
//     source: "3",
//     target: "4",
//     label: "Factures Générées",
//     type: "smoothstep",
//     style: { stroke: "#8A2BE2" },
//   },
//   {
//     id: "e4-5",
//     source: "4",
//     target: "5",
//     label: "Envoi Automatique vers ZEENDOC",
//     type: "smoothstep",
//     style: { stroke: "#8A2BE2" },
//   },
// ];
export default function Graph() {
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  function onNodesChange(changes) {
    setNodes((nds) => applyNodeChanges(changes, nds));
  }
  const onEdgesChange = (changes) =>
    setEdges((eds) => applyEdgeChanges(changes, eds));
  const onConnect = (params) =>
    setEdges((eds) =>
      addEdge(
        {
          ...params,
          style: { stroke: "#8A2BE2", strokeDasharray: "10, 10" },
          type: "baseEdge",
        },
        eds
      )
    );
  //TODO set hehight properly
  const nodeTypes = {
    baseNode: BaseNode,
  };

  const edgeTypes = {
    baseEdge: BaseEdge,
  };
  return (
    <div style={{ height: "700px" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        // edgeTypes={{ default: { type: "step" } }}
        // nodeTypes={{
        //   default: {
        //     style: {
        //       background: "#fff",
        //       border: "1px solid #000",
        //       padding: 10,
        //     },
        //     selectedStyle: {
        //       background: "#f0f0f0",
        //       border: "1px solid #000",
        //       padding: 10,
        //     },
        //   },
        // }}
      >
        {/* <MiniMap /> */}
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  );
}
