import { Box, Card } from "@mui/joy";
import { default as React, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

export default function Notes() {
  const [markdown, setMarkdown] = useState(`Notes\n
        @Xavier que penses-tu de ce bug ?
J’ai check les données d’entrée, pas de problème\n
-> Oui j’ai check @bertrand, j’attends le retour du client. Il m’a donné ce nouveau fichier de donnée suite à la MEP de son ERP`);

  return (
    <Box style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      {/* Editable TextArea */}
      {/* <Textarea
        minRows={6}
        placeholder="Write your markdown here..."
        value={markdown}
        onChange={(e) => setMarkdown(e.target.value)}
      /> */}

      {/* Rendered Markdown */}
      <Card sx={{ width: 800 }}>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{markdown}</ReactMarkdown>
      </Card>
    </Box>
  );
}
