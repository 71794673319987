import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Input,
  Option,
  Select,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  useTheme,
} from "@mui/joy";
import React, { useState } from "react";
export default function IOSetup() {
  const [authType, setAuthType] = useState("password");
  const [connectorType, setConnectorType] = useState(
    "SFTP -> Sage X3 (customers)"
  );
  const theme = useTheme();
  return (
    <Box>
      <Box mt={1} ml={2} mr={2}>
        <Typography level="h4" color="neutral" sx={{ fontWeight: 200 }}>
          Connector
        </Typography>
        <Select
          variant="outlined"
          color="primary"
          sx={{
            width: 300,
            mt: 1,
            border: `1.5px solid ${theme.palette.primary[500]}`,
            zIndex: 1500,
          }}
          indicator={<KeyboardArrowDown />}
          onChange={(e, newValue) => setConnectorType(newValue)}
          value={connectorType}
          slotProps={{
            listbox: {
              sx: {
                zIndex: 1500,
              },
            },
          }}
        >
          <Option value="SFTP -> Sage X3 (customers)" sx={{ zIndex: 999 }}>
            SFTP {"->"} Sage X3 - customers
          </Option>
          <Option value="Sage X3 - suppliers">Sage X3 - suppliers</Option>
          <Option value="Sage 100 - invoices">Sage 100 - invoices</Option>
        </Select>
        <Typography level="h4" color="neutral" sx={{ fontWeight: 200, mt: 1 }}>
          Setup
        </Typography>
        <Box
          sx={{
            flex: 1,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Card
            sx={{
              mt: 1,
              border: `1.5px solid ${theme.palette.primary[500]}`,
              maxHeight: "100%",
              overflow: "auto",
              mb: 2,
            }}
          >
            {connectorType === "SFTP -> Sage X3 (customers)" ? (
              <Box>
                <Tabs variant="plain" defaultValue="sftp">
                  <TabList>
                    <Tab disableIndicator value="sftp">
                      SFTP
                    </Tab>
                    <Tab disableIndicator value="authentication">
                      X3 authentication
                    </Tab>
                    <Tab disableIndicator value="customer_mapping">
                      Mapping
                    </Tab>
                  </TabList>
                  <TabPanel value="sftp">
                    <Typography
                      level="title-lg"
                      color="neutral"
                      sx={{ fontWeight: 200 }}
                    >
                      Host
                    </Typography>
                    <Input type="text" size="sm" sx={{ width: 300 }} />
                    <Typography
                      level="title-lg"
                      color="neutral"
                      sx={{ fontWeight: 200, mt: 0.5 }}
                    >
                      Username
                    </Typography>
                    <Input type="text" size="sm" sx={{ width: 300 }} />

                    <Typography
                      level="title-lg"
                      color="neutral"
                      sx={{ fontWeight: 200, mt: 0.5 }}
                    >
                      Authentication Type
                    </Typography>
                    <Select
                      sx={{
                        width: 300,
                        zIndex: 1500,
                      }}
                      onChange={(e, newValue) => setAuthType(newValue)}
                      value={authType}
                      size="sm"
                      slotProps={{
                        listbox: {
                          sx: {
                            zIndex: 1500,
                          },
                        },
                      }}
                    >
                      <Option value="password">Password</Option>
                      <Option value="ssh">SSH</Option>
                    </Select>

                    {authType === "password" ? (
                      <>
                        <Typography
                          level="title-lg"
                          color="neutral"
                          sx={{ fontWeight: 200, mt: 0.5 }}
                        >
                          Password
                        </Typography>
                        <Input type="text" size="sm" sx={{ width: 300 }} />
                      </>
                    ) : (
                      <>
                        <Typography
                          level="title-lg"
                          color="neutral"
                          sx={{ fontWeight: 200, mt: 0.5 }}
                        >
                          Private SSH Key
                        </Typography>
                        <Input type="text" size="sm" sx={{ width: 300 }} />
                      </>
                    )}

                    <Typography
                      level="title-lg"
                      color="neutral"
                      sx={{ fontWeight: 200, mt: 0.5 }}
                    >
                      Folder Path
                    </Typography>
                    <Input type="text" size="sm" sx={{ width: 300 }} />

                    <Typography
                      level="title-lg"
                      color="neutral"
                      sx={{ fontWeight: 200, mt: 0.5 }}
                    >
                      Prefix
                    </Typography>
                    <Input type="text" size="sm" sx={{ width: 300 }} />
                  </TabPanel>
                  <TabPanel value="authentication">In construction...</TabPanel>
                  <TabPanel value="customer_mapping">
                    In construction...
                  </TabPanel>
                </Tabs>
              </Box>
            ) : (
              <Box>In construction...</Box>
            )}
          </Card>
        </Box>
        <Button
          variant="outlined"
          color="primary"
          sx={{ mt: 1, border: `1.5px solid ${theme.palette.primary[500]}` }}
        >
          Test connection
        </Button>
      </Box>
    </Box>
  );
}
