import { Box, Stack, useTheme } from "@mui/joy";
import React from "react";
import Header from "../header/Header";
import NavBar from "../navbar/NavBar";

export default function Root({ children }) {
  const theme = useTheme();
  return (
    <Stack direction="row">
      <NavBar />
      <Stack direction="column" width="100%" height="100vh">
        <Box
          sx={{ backgroundColor: theme.palette.background.level1 }}
          height="100%"
        >
          <Header />
          {children}
        </Box>
      </Stack>
    </Stack>
  );
}
