export function createWorker() {
  const codeWorkerBlob = new Blob(
    [
      `onmessage = function (e) {
            try {
                const { code, input } = e.data;
                const func = new Function("input", code);
                const result = func(input);
                postMessage({ success: true, result });
            } catch (error) {
                postMessage({ success: false, error });
            }
        };`,
    ],
    { type: "application/javascript" }
  );
  return new Worker(URL.createObjectURL(codeWorkerBlob));
}

// onmessage = function (e) {
//   try {
//     const { code, input } = e.data;
//     const func = new Function("input", code);
//     const result = func(input);
//     postMessage({ success: true, result });
//   } catch (error) {
//     postMessage({ success: false, error });
//   }
// };
