import { CheckCircle, MoreVert, PlayArrowOutlined } from "@mui/icons-material";
import { Box, IconButton, Link, Stack, Typography } from "@mui/joy";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

export default function Flow({ flow }) {
  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Link to={`/flow/${flow.id}`} component={RouterLink}>
          <Typography level="title-md">{flow.name}</Typography>
        </Link>
        <Stack direction="row" alignItems="center">
          <Status status={flow.status} />
          <IconButton color="primary" variant="plain">
            <PlayArrowOutlined />
          </IconButton>
          <IconButton>
            <MoreVert />
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  );
}

function Status({ status }) {
  return (
    <IconButton color="success">
      <CheckCircle />
    </IconButton>
  );
}
