import { Check, InfoOutlined } from "@mui/icons-material";
import {
  Alert,
  AspectRatio,
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Typography,
} from "@mui/joy";
import React, { useEffect, useState } from "react";
import { usePasswordResetSendEmailMutation } from "../../services/api";
import { isApiError, isApiFulfilled, isApiPending } from "../utils/utils";

function PasswordResetRequest() {
  const [email, setEmail] = useState("");
  const [sendEmail, sendEmailResult] = usePasswordResetSendEmailMutation();
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      if (email === "") {
        setEmailError("");
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        setEmailError("Invalid email format");
      } else {
        setEmailError("");
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [email]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (emailError === "") {
      await sendEmail({ email });
    }
  };

  return (
    <Container maxWidth="sm">
      {isApiFulfilled(sendEmailResult) ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Alert
            size="lg"
            color="success"
            variant="solid"
            invertedColors
            startDecorator={
              <AspectRatio
                variant="solid"
                ratio="1"
                sx={{
                  minWidth: 40,
                  borderRadius: "50%",
                  boxShadow: "0 2px 12px 0 rgb(0 0 0/0.2)",
                }}
              >
                <div>
                  <Check fontSize="medium" />
                </div>
              </AspectRatio>
            }
            sx={{ alignItems: "flex-start", overflow: "hidden" }}
          >
            <Box sx={{ pl: 2, pr: 4 }}>
              <Typography level="title-lg">Success</Typography>
              <Typography level="body-sm">
                If your email is associated with an existing SyncPulse account,
                you will receive an email with instructions to reset your
                password.
              </Typography>
            </Box>
          </Alert>
        </Box>
      ) : (
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ height: "100vh" }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <img
            src="/syncpulse_2048.png"
            alt="SyncPulse Logo"
            style={{
              display: "block",
              margin: "0 auto",
              width: "60px",
              height: "60px",
            }}
          />
          <Typography
            level="h1"
            gutterBottom
            color="primary"
            mt={4}
            sx={{ textAlign: "center" }}
          >
            Reset Password
          </Typography>
          <FormControl
            error={!!emailError}
            sx={{ mt: 4, textAlign: "center", width: "380px" }}
          >
            <FormLabel>
              <Typography variant="plain" color="neutral">
                Email Address
              </Typography>
            </FormLabel>
            <Input
              type="email"
              fullWidth
              variant="plain"
              color="primary"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailError && (
              <FormHelperText>
                <InfoOutlined />
                {emailError}
              </FormHelperText>
            )}
          </FormControl>
          <Button
            type="submit"
            variant="solid"
            color="primary"
            sx={{ mt: 4 }}
            disabled={!email}
            loading={isApiPending(sendEmailResult)}
          >
            Send Reset Link
          </Button>
          {isApiError(sendEmailResult) && (
            <Alert color="danger" variant="soft" sx={{ mt: 4 }}>
              Failed to send email. Try again.
            </Alert>
          )}
        </Box>
      )}
      <Box height="60px"></Box>
    </Container>
  );
}

export default PasswordResetRequest;
