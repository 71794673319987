import { Editor } from "@monaco-editor/react";
import { AutoAwesome } from "@mui/icons-material";
import { Box, Button, IconButton, Stack } from "@mui/joy";
import React, { useState } from "react";
import { useFlowData } from "../Flow";
import { createWorker } from "./worker";

export default function CodeEditor({ handleEditMode, code, setCode }) {
  const { dataMode, testData, realData, setOutputData } = useFlowData();
  const [running, setRunning] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);

  async function handleRunCode() {
    setRunning(true);
    const myWorker = createWorker();
    myWorker.onmessage = function (e) {
      if (e.data.success) {
        handleOutputData({ setOutputData, data: e.data.result });
      } else {
        setError(e.data.error);
      }
      setRunning(false);
    };
    myWorker.postMessage({
      code,
      input: dataMode === "test" ? testData : realData,
    });
  }

  return (
    <Stack
      direction="column"
      height="100%"
      width="100%"
      //   sx={{ backgroundColor: "#1e1e1e" }}
    >
      <Box height="100%">
        <Editor
          height="100%"
          language="javascript"
          theme="vs"
          value={code}
          onChange={setCode}
        />
      </Box>
      {/* {error && <pre>{JSON.stringify(error, null, 2)}</pre>} */}

      <Stack
        direction="row"
        justifyContent="space-evenly"
        p={1}
        // sx={{ backgroundColor: "#1e1e1e" }}
      >
        <IconButton onClick={() => handleEditMode("ui")} color="primary">
          <AutoAwesome />
        </IconButton>
        <Button
          color="primary"
          variant="solid"
          onClick={handleRunCode}
          loading={running}
        >
          Run
        </Button>
      </Stack>

      {/* <Box>{result && <pre>{JSON.stringify(result, null, 2)}</pre>}</Box> */}
    </Stack>
  );
}

function handleOutputData({ setOutputData, data }) {
  if (data == null || !Array.isArray(data.files) || data.files === null) {
    return;
  } else {
    setOutputData(data);
  }
}
