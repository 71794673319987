import {
  Stack,
  styled,
  Switch,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  useTheme,
} from "@mui/joy";
import React, { useState } from "react";
import { useFlowData } from "../Flow";
import GroupData from "./GroupData";

const CustomTab = styled(Tab)(({ theme }) => ({
  "&.MuiTab-root": {
    borderRadius: "10px 10px 0px 0px",
    padding: "0px 12px",
    minHeight: "20px",
    paddingLeft: "16px",
    paddingRight: "16px",
    marginRight: "4px",
    // borderBottom: "none",
  },
  "&:not(.Mui-selected)": {
    backgroundColor: "inherit",
    color: theme.palette.primary[700],
  },
  "&.MuiTab-root:hover": {
    color: "white",
    backgroundColor: theme.palette.primary[400],
  },
  "&.Mui-selected": {
    color: "white",
    backgroundColor: theme.palette.primary[500],
  },
  "&.Mui-selected:hover": {
    backgroundColor: theme.palette.primary[500],
  },
}));

const TabInput = styled(CustomTab)(({ theme }) => ({
  "&.MuiTab-root": {
    borderRadius: "10px 10px 0px 0px",
    padding: "0px 4px",
    minHeight: "20px",
    marginRight: "4px",
    // borderBottom: "none",
  },
}));

export default function Input() {
  const {
    dataMode,
    setDataMode,
    testData,
    setTestData,
    realData,
    setRealData,
    contextData,
    setContextData,
    outputData,
    setOutputData,
  } = useFlowData();
  //   const [dataMode, setDataMode] = useState("test");
  //   const [testData, setTestData] = useState({});
  //   const [realData, setRealData] = useState({});
  const [uploadFileModalOpen, setUploadFileModalOpen] = useState(false);

  const stepInputs = [
    {
      id: "7807087tuiaytuoay67896phjhl",
      name: "Hubpost connection",
    },
  ];

  const theme = useTheme();

  return (
    <Stack direction="column" height="100%" width="100%">
      <Stack direction="row" justifyContent="space-between">
        <Typography
          level="h4"
          color="neutral"
          sx={{ fontWeight: 200 }}
          pl={0.5}
        >
          INPUT
        </Typography>
        <Typography
          level="body-sm"
          component="label"
          endDecorator={
            <Switch
              sx={{ ml: 0.5 }}
              size="sm"
              checked={dataMode === "test"}
              onChange={(e) => setDataMode(e.target.checked ? "test" : "real")}
            />
          }
          mr={1}
        >
          Test data
        </Typography>
      </Stack>
      <Tabs
        orientation="horizontal"
        size="sm"
        variant="plain"
        sx={{
          borderBottom: 0,
          backgroundColor: theme.colorSchemes.light.palette.background.level1,
          height: "100%",
        }}
        defaultValue="home"
      >
        <TabList
          sx={{
            ml: 2,
            borderBottom: 0,
            boxShadow: "none",
          }}
          disableUnderline
        >
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Stack direction="row">
              {stepInputs.map((input) => (
                <TabInput
                  variant="solid"
                  color="primary"
                  theme={theme}
                  value={input.id}
                  disableIndicator
                  key={input.id}
                >
                  <Typography
                    level="body-sm"
                    sx={{
                      maxWidth: "150px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      color: "white", //TODO change following whetehr tab is selected
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    {dataMode === "test" ? "TEST - " : ""}
                    {input.name}
                  </Typography>
                </TabInput>
              ))}
            </Stack>
            <Stack direction="row">
              <CustomTab
                variant="solid"
                color="primary"
                value={"context"}
                disableIndicator
              >
                Context
              </CustomTab>
            </Stack>
          </Stack>
        </TabList>

        {stepInputs.map((input) => (
          <TabPanel
            sx={{ p: 0, height: "100%" }}
            value={input.id}
            key={input.id}
          >
            <GroupData
              data={
                dataMode === "test" ? testData[input.id] : realData[input.id]
              }
              setData={setInputData({
                inputId: input.id,
                setTestData,
                setRealData,
                dataMode,
              })}
              writeMode={dataMode === "test"}
            />
          </TabPanel>
        ))}
        <TabPanel sx={{ p: 0, height: "100%" }} value={"context"}>
          <GroupData
            data={contextData}
            setData={setContextData}
            writeMode={true}
          />
        </TabPanel>
      </Tabs>
    </Stack>
  );
}

const setInputData =
  ({ inputId, setTestData, setRealData, dataMode }) =>
  (data) => {
    if (dataMode === "test") {
      setTestData((prevData) => ({
        ...prevData,
        [inputId]: data,
      }));
    } else {
      setRealData((prevData) => ({
        ...prevData,
        [inputId]: data,
      }));
    }
  };
