import { Box, CircularProgress, Stack, Typography } from "@mui/joy";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Flow from "./app/flow/Flow";
import { Login } from "./app/login/Login";
import PasswordReset from "./app/password_reset/PasswordReset";
import PasswordResetRequest from "./app/password_reset/PasswordResetRequest";
import Signup from "./app/signup/Signup";
import Workspace from "./app/workspace/Workspace";
import { useAuth } from "./AuthProvider";

//TODO ajouter bouton pour aller sur google duepuis browser headless
export function App() {
  const auth = useAuth();
  const isProduction = process.env.NODE_ENV === "production";

  //TODO2 check comment mieux sécuriser

  //For new non-authenticated routes, add them in AuthProvider.js
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/password-reset" element={<PasswordReset />} />
      <Route
        path="/password-reset/request"
        element={<PasswordResetRequest />}
      />
      <Route
        path="*"
        element={
          auth.isAuthenticated ? (
            <Routes>
              <Route path="/flow/:flowId" element={<Flow />} />
              <Route path="/workspace/:workspaceId" element={<Workspace />} />
              {/* <Route path="/" element={<Navigate to="/recorder" />} />
              {!isProduction && <Route path="/home" element={<Home />} />}
              {!isProduction && <Route path="/account" element={<Account />} />}
              <Route path="/recorder" element={<Recorder />} />
              <Route path="*" element={<NotFound />} /> */}
            </Routes>
          ) : (
            <Box
              sx={{
                width: `100%`,
                height: `100vh`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack
                direction="column"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress size="lg" variant="solid" />
                <Typography>Authenticating...</Typography>
              </Stack>
            </Box>
          )
        }
      />
    </Routes>
  );
}

// export function App() {
//   return (
//     <Router>
//       <AppContent />
//     </Router>
//   );
// }
