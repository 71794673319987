import {
  ArrowBack,
  ArrowForward,
  Close,
  KeyboardArrowDown,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  Option,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/joy";
import { Drawer } from "@mui/material";
// import { GlobalWorkerOptions } from "pdfjs-dist/build/pdf";
// import pdfWorker from "pdfjs-dist/build/pdf.worker.entry";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useChatMutation } from "../../services/api";
import Root from "../layout/Root";
import { FlowProvider, useFlow } from "./FlowContext";
import Graph from "./graph/Graph";
import IOSetup from "./IOSetup";
import Input from "./syncpulseAISetup/Input";
import Output from "./syncpulseAISetup/Output";
import SyncPulseAISetup from "./syncpulseAISetup/SyncPulseAISetup";

// Set the workerSrc for PDF.js
// GlobalWorkerOptions.workerSrc = pdfWorker;

const FlowDataContext = createContext();

const fakeContextData = {
  // files: [
  // ],
};

function FlowDataProvider({ children }) {
  const [dataMode, setDataMode] = useState("test");
  const [testData, setTestData] = useState({});
  const [realData, setRealData] = useState({});
  const [contextData, setContextData] = useState(fakeContextData);
  const [outputData, setOutputData] = useState({});

  useEffect(() => {
    async function fetchTestData() {
      const response = await fetch("/demo/test_data.csv");
      const text = await response.text();
      setTestData({
        "7807087tuiaytuoay67896phjhl": {
          files: [
            {
              id: "tzuiy678eyu789",
              name: "transactions.csv",
              type: "text/csv",
              content: text,
            },
          ],
        },
      });
    }
    async function fetchFakeContextData() {
      const response = await fetch("/demo/notice.pdf");
      const arrayBuffer = await response.arrayBuffer();
      setContextData({
        files: [
          {
            id: "notice.pdf",
            name: "notice.pdf",
            type: "application/pdf",
            content: arrayBuffer,
          },
        ],
      });
    }
    fetchTestData();
    fetchFakeContextData();
  }, []);

  return (
    <FlowDataContext.Provider
      value={{
        dataMode,
        setDataMode,
        testData,
        setTestData,
        realData,
        setRealData,
        contextData,
        setContextData,
        outputData,
        setOutputData,
      }}
    >
      {children}
    </FlowDataContext.Provider>
  );
}

export function useFlowData() {
  return useContext(FlowDataContext);
}

function renderValue({ option, theme }) {
  if (!option) {
    return null;
  }

  return (
    <React.Fragment>
      {/* <ListItemDecorator>
        <NodeIcon type={option.value} theme={theme} />
      </ListItemDecorator> */}
      {option.label}
    </React.Fragment>
  );
}

export default function Flow() {
  return (
    <FlowProvider>
      <FlowDataProvider>
        <FlowContent />
      </FlowDataProvider>
    </FlowProvider>
  );
}

function FlowContent() {
  const [nodeFamily, setNodeFamily] = useState("syncpulseAI");
  const [inputOutputForSyncpulseAI, setInputOutputForSyncpulseAI] =
    useState(true);
  const [chatInput, setChatInput] = useState("");
  const [oldMessages, setOldMessages] = useState([]);
  const [chat, result] = useChatMutation();

  const { flowId } = useParams();
  const flow = useFlow();
  const theme = useTheme();
  return (
    <Root>
      <Box p={4}>
        <Typography
          level="h2"
          fontFamily="Lexend"
          fontWeight={400}
          color="neutral"
        >
          Flow {flowId}
        </Typography>
        {/* <ChatTest /> */}
      </Box>
      <Graph />
      <Drawer
        open={flow.drawerNode != null}
        onClose={() => flow.setDrawerNode(null)}
        anchor="right"
        variant="persistent"
        SlideProps={{
          style: {
            borderTopLeftRadius: 16,
            borderBottomLeftRadius: 16,
            border: `2.5px solid ${theme.palette.primary[500]}`,
            backgroundColor: theme.palette.primary.plainHoverBg,
            maxWidth: null,
            width:
              inputOutputForSyncpulseAI && nodeFamily === "syncpulseAI"
                ? "100%"
                : null,
          },
        }}

        // sx={{ height: "100vh" }}
      >
        <Stack direction="row" height="100%" width="100%">
          {nodeFamily === "syncpulseAI" && (
            <Box
              borderRight={`1.5px solid ${theme.palette.primary[500]}`}
              height="100%"
              width="48px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <IconButton
                onClick={() =>
                  setInputOutputForSyncpulseAI(!inputOutputForSyncpulseAI)
                }
                color="primary"
              >
                {inputOutputForSyncpulseAI ? <ArrowForward /> : <ArrowBack />}
              </IconButton>
            </Box>
          )}
          {nodeFamily === "syncpulseAI" && inputOutputForSyncpulseAI && (
            <Stack
              direction="column"
              height="100%"
              width="100%"
              borderRight={`1.5px solid ${theme.palette.primary[500]}`}
            >
              <Box
                height="50%"
                bgcolor={theme.palette.background.level1}
                borderBottom={`1.5px solid ${theme.palette.primary[500]}`}
              >
                <Input />
              </Box>
              <Box height="50%" bgcolor={theme.palette.background.level1}>
                <Output />
              </Box>
            </Stack>
          )}
          <Stack
            direction="column"
            height="100%"
            width="700px"
            minWidth="700px"
          >
            <Stack direction="row" justifyContent="space-between" pl={2}>
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography
                  pt={1}
                  level="h3"
                  fontFamily="Lexend"
                  fontWeight={300}
                  color="neutral"
                  sx={{ cursor: "pointer" }}
                >
                  Hubspot connection
                </Typography>
                {/* <IconButton sx={{ mt: 1 }}>
              <Edit sx={{ fontSize: 16 }} />
            </IconButton> */}
              </Stack>
              <IconButton onClick={() => flow.setDrawerNode(null)}>
                <Close />
              </IconButton>
            </Stack>
            <Box mt={2} ml={2} mr={2}>
              <Typography level="h4" color="neutral" sx={{ fontWeight: 200 }}>
                Type
              </Typography>
              <Select
                // defaultValue="iO"
                renderValue={(option) => renderValue({ option, theme })}
                variant="outlined"
                color="primary"
                sx={{
                  width: 300,
                  mt: 1,
                  border: `1.5px solid ${theme.palette.primary[500]}`,
                  zIndex: 1500, // Ensure the Select component has a high zIndex
                }}
                indicator={<KeyboardArrowDown />}
                onChange={(e, newValue) => setNodeFamily(newValue)}
                value={nodeFamily}
                slotProps={{
                  listbox: {
                    sx: {
                      zIndex: 1500, // Ensure the Listbox component has a high zIndex
                    },
                  },
                }}
              >
                <Option value="iO" sx={{ zIndex: 999 }}>
                  {/* <ListItemDecorator>
                <NodeIcon type="io" theme={theme} />
              </ListItemDecorator> */}
                  iO connector
                </Option>
                <Option value="syncpulseAI">
                  {/* <ListItemDecorator>
                <NodeIcon type="syncpulseAI" theme={theme} />
              </ListItemDecorator> */}
                  SyncPulse AI
                </Option>
              </Select>
            </Box>
            {nodeFamily === "iO" && <IOSetup />}
            {nodeFamily === "syncpulseAI" && <SyncPulseAISetup />}
          </Stack>
        </Stack>
      </Drawer>
    </Root>
  );
}
