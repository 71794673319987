import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const adminBaseUrl = "https://x8ki-letl-twmt.n7.xano.io/api:6sM1QE1A";
const mainBaseUrl = "https://x8ki-letl-twmt.n7.xano.io/api:hnEgP7uB";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["userDefaultWorkflows"], // Déclaration des tags
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ email, password }) => ({
        url: adminBaseUrl + "/auth/login",
        method: "POST",
        body: { email, password },
      }),
    }),
    me: builder.query({
      query: () => ({
        url: adminBaseUrl + "/auth/me",
        method: "GET",
      }),
    }),
    renewToken: builder.mutation({
      query: ({ tokenToUseForRenew }) => {
        let query = {
          url: adminBaseUrl + "/renewToken",
          method: "GET",
          headers: {},
        };
        if (tokenToUseForRenew) {
          query.headers = {
            Authorization: `Bearer ${tokenToUseForRenew}`,
          };
        }
        return query;
      },
    }),
    userDefaultWorkflows: builder.query({
      query: () => ({
        url: adminBaseUrl + "/userWorkflows",
        method: "GET",
      }),
      providesTags: ["userDefaultWorkflows"],
    }),
    workflow: builder.mutation({
      query: ({ content, name, type, version }) => ({
        url: mainBaseUrl + "/workflow/v2",
        method: "POST",
        body: {
          content,
          name,
          type,
          version,
        },
      }),
      invalidatesTags: ["userDefaultWorkflows"],
    }),
    deleteWorkflow: builder.mutation({
      query: ({ workflowId }) => ({
        url: mainBaseUrl + "/workflow",
        method: "DELETE",
        body: { workflow_id: workflowId },
      }),
      invalidatesTags: ["userDefaultWorkflows"],
    }),
    workflowRun: builder.mutation({
      query: ({ workflowId, message, success }) => ({
        url: mainBaseUrl + "/workflowRun",
        method: "POST",
        body: { workflow_id: workflowId, message, success },
      }),
    }),
    signup: builder.mutation({
      query: ({ email, password, organizationName }) => ({
        url: adminBaseUrl + "/auth/signup",
        method: "POST",
        body: { email, password, organization_name: organizationName },
      }),
    }),
    passwordReset: builder.mutation({
      query: ({ magic_token, password }) => ({
        url: adminBaseUrl + "/auth/password-reset",
        method: "POST",
        body: { magic_token, password },
      }),
    }),
    passwordResetSendEmail: builder.mutation({
      query: ({ email }) => ({
        url: adminBaseUrl + "/auth/password-reset/send-email",
        method: "POST",
        body: { email },
      }),
    }),
    chat: builder.mutation({
      query: ({ messages }) => ({
        url: "https://x8ki-letl-twmt.n7.xano.io/api:0o5IxBWk/node/chat",
        method: "POST",
        body: {
          messages,
        },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useMeQuery,
  useWorkflowMutation,
  useWorkflowRunMutation,
  useUserDefaultWorkflowsQuery,
  useDeleteWorkflowMutation,
  useRenewTokenMutation,
  useSignupMutation,
  usePasswordResetMutation,
  usePasswordResetSendEmailMutation,
  useChatMutation,
} = api;
