import { Box, Divider, Stack } from "@mui/joy";
import React from "react";
import Flow from "./Flow";

export default function Flows() {
  const flows = [
    {
      id: 1,
      name: "Flow 1",
      status: "success",
    },
    {
      id: 2,
      name: "Flow 2",
      status: "failed",
    },
    {
      id: 3,
      name: "Flow 3",
      status: "success",
    },
    {
      id: 4,
      name: "Flow 4",
      status: "success",
    },
  ];
  return (
    <Box pl={2} pr={2} width={800}>
      <Stack direction="column" divider={<Divider />}>
        {flows.map((flow) => (
          <Flow key={flow.id} flow={flow} />
        ))}
      </Stack>
    </Box>
  );
}
