import { Code } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Stack,
  Textarea,
  Typography,
  useTheme,
} from "@mui/joy";
import React, { useEffect, useState } from "react";
import { useChatMutation } from "../../../services/api";

const fakeMessages = [
  {
    content:
      "Unzip the files. You'll get CFONB bank statements. Concatenate all those files in one, unique file",
    role: "user",
    ts: 1,
  },
  {
    content:
      "I've tried to unzip the files but it is encrypted, do you want me to add the password in your environment so that i can use it after ?",
    role: "AI",
    ts: 2,
  },
  {
    content: "Yep, here it is : ************",
    role: "user",
    ts: 3,
  },
  {
    content:
      "Password recorded in the environment as Intesa zip password Script successfully built. Is the output good ? ",
    role: "AI",
    ts: 4,
  },
];

export default function Chat({ inTransition, handleEditMode }) {
  const theme = useTheme();
  const [textareaValue, setTextareaValue] = useState("");
  const [allMessages, setAllMessages] = useState([]);
  const [displayedMessages, setDisplayedMessages] = useState([]);
  const [chat, result] = useChatMutation();

  useEffect(() => {
    if (result.isSuccess) {
      handleMessage({
        result,
        setAllMessages,
        setDisplayedMessages,
        handleSendMessage,
      });
    }
  }, [result.isSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSendMessage(textareaValue);
  };

  const handleSendMessage = (value) => {
    const newMessage = { role: "user", content: value };
    setAllMessages((prevMessages) => [...prevMessages, newMessage]);
    setDisplayedMessages((prevMessages) => [
      ...prevMessages,
      { ...newMessage, ts: Date.now() },
    ]);
    chat({ messages: [...allMessages, newMessage] });
    setTextareaValue("");
  };

  console.log(displayedMessages);

  return (
    <Box
      width="100%"
      height="100%"
      overflow="auto"
      sx={{ backgroundColor: "white" }}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box height="100%">
        {displayedMessages.map((message) => (
          <Box key={message.ts}>
            {message.role === "user" && (
              <UserMessage content={message.content} />
            )}
            {message.role === "assistant" && (
              <AIMessage content={message.content} />
            )}
          </Box>
        ))}
      </Box>
      {!inTransition && (
        <Stack direction="row" alignItems="end">
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Box
              borderTop="1px solid #e0e0e0"
              m={2}
              mr={1}
              p={1}
              borderRadius="18px"
              sx={{ backgroundColor: theme.palette.background.level2 }}
              display="flex"
              flexDirection="row"
              alignItems="end"
            >
              <AttachFileButton textareaValue={textareaValue} />
              <MessageInput
                textareaValue={textareaValue}
                setTextareaValue={setTextareaValue}
                handleSubmit={handleSubmit}
              />
              <SendMessageButton
                textareaValue={textareaValue}
                handleSubmit={handleSubmit}
              />
            </Box>
          </form>
          <IconButton
            onClick={() => handleEditMode("code")}
            sx={{ width: "32px", height: "32px", mb: 3, mr: 1 }}
            color="primary"
          >
            <Code />
          </IconButton>
        </Stack>
      )}
    </Box>
  );
}

function UserMessage({ content }) {
  const theme = useTheme();
  return (
    <Box display="flex" justifyContent="flex-end" m={1} mb={2}>
      <Box
        borderRadius={"18px"}
        p={1}
        maxWidth="70%"
        sx={{ backgroundColor: theme.palette.background.level2 }}
      >
        <Typography level="body-sm">{content}</Typography>
      </Box>
    </Box>
  );
}

function AIMessage({ content }) {
  return (
    <Box display="flex" justifyContent="flex-start" m={1} mb={2}>
      <Typography level="body-sm">{content}</Typography>
    </Box>
  );
}

function MessageInput({ textareaValue, setTextareaValue, handleSubmit }) {
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <Textarea
      value={textareaValue}
      onChange={(e) => setTextareaValue(e.target.value)}
      onKeyPress={handleKeyPress}
      placeholder="Message SyncPulse AI"
      sx={{
        boxShadow: "none",
        border: "none",
        backgroundColor: "transparent",
        width: "100%",
        "&::before": {
          display: "none",
        },
        "&:focus-within": {
          outline: "none",
          outlineOffset: "2px",
        },
      }}
    />
  );
}

function AttachFileButton({ textareaValue }) {
  return (
    <IconButton>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 7C9 4.23858 11.2386 2 14 2C16.7614 2 19 4.23858 19 7V15C19 18.866 15.866 22 12 22C8.13401 22 5 18.866 5 15V9C5 8.44772 5.44772 8 6 8C6.55228 8 7 8.44772 7 9V15C7 17.7614 9.23858 20 12 20C14.7614 20 17 17.7614 17 15V7C17 5.34315 15.6569 4 14 4C12.3431 4 11 5.34315 11 7V15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15V9C13 8.44772 13.4477 8 14 8C14.5523 8 15 8.44772 15 9V15C15 16.6569 13.6569 18 12 18C10.3431 18 9 16.6569 9 15V7Z"
          fill="currentColor"
        ></path>
      </svg>
    </IconButton>
  );
}

function SendMessageButton({ textareaValue, handleSubmit }) {
  return (
    <IconButton
      variant="plain"
      sx={{ width: "32px", height: "32px" }}
      disabled={textareaValue.length === 0}
      onClick={handleSubmit}
    >
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.1918 8.90615C15.6381 8.45983 16.3618 8.45983 16.8081 8.90615L21.9509 14.049C22.3972 14.4953 22.3972 15.2189 21.9509 15.6652C21.5046 16.1116 20.781 16.1116 20.3347 15.6652L17.1428 12.4734V22.2857C17.1428 22.9169 16.6311 23.4286 15.9999 23.4286C15.3688 23.4286 14.8571 22.9169 14.8571 22.2857V12.4734L11.6652 15.6652C11.2189 16.1116 10.4953 16.1116 10.049 15.6652C9.60265 15.2189 9.60265 14.4953 10.049 14.049L15.1918 8.90615Z"
          fill="currentColor"
        ></path>
      </svg>
    </IconButton>
  );
}

function handleMessage({
  result,
  setAllMessages,
  setDisplayedMessages,
  handleSendMessage,
}) {
  console.log(result);
  try {
    console.log(1);

    const message = result.data.result.choices[0].message;
    const contentStr = message.content; //.replace(/(\w+):/g, '"$1":'); // Add quotes around keys

    let messageContent;
    try {
      messageContent = JSON.parse(contentStr);
    } catch (error) {
      handleSendMessage("Your answer is not a valid JSON");
      throw new Error(
        "Invalid content: type and content must not be null or empty"
      );
    }
    if (
      !messageContent.type ||
      messageContent.type === "" ||
      !messageContent.content ||
      messageContent.content === ""
    ) {
      handleSendMessage(
        "Invalid content: type and content properties must not be null or empty"
      );
      throw new Error(
        "Invalid content: type and content must not be null or empty"
      );
    }

    console.log(2);
    switch (messageContent.type) {
      case "message":
        console.log(3);
        setDisplayedMessages((prevMessages) => [
          ...prevMessages,
          {
            role: "assistant",
            content: messageContent.content,
            ts: Date.now(),
          },
        ]);
        break;
      case "code":
        console.log(messageContent.content);
        break;
      case "secret_request":
        setDisplayedMessages((prevMessages) => [
          ...prevMessages,
          {
            role: "assistant",
            content: "Give me your password !!!",
            ts: Date.now(),
          },
        ]);
        break;
      default:
        handleSendMessage(`Unknown message type: ${message.type}`);
        throw new Error(`Unknown message type: ${message.type}`);
    }
    setAllMessages((prevMessages) => [
      ...prevMessages,
      {
        role: "assistant",
        content: messageContent.content,
      },
    ]);
  } catch (error) {
    console.log(error);
  }
}
