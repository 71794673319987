import { Box, Button, Input, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthProvider";
import { useLoginMutation } from "../../services/api";

export function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login, { isLoading }] = useLoginMutation();
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "SyncPulse - Login";
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    await login({ email, password })
      .unwrap()
      .then((res) => {
        auth.logIn({ token: res.authToken });
        navigate(auth.redirectTo);
      })
      .catch((err) => {
        console.error("Failed to login: ", err);
      });
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        padding: 2,
        maxWidth: "400px",
        margin: "0 auto",
      }}
    >
      <Typography level="h4" component="h1" gutterBottom>
        Login
      </Typography>
      <Input
        placeholder="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
        sx={{ marginBottom: 2, width: "100%" }}
      />
      <Input
        placeholder="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
        sx={{ marginBottom: 2, width: "100%" }}
      />
      <Button type="submit" loading={isLoading} sx={{ width: "100%" }}>
        Login
      </Button>
    </Box>
  );
}
