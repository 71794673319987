import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isApiPending, sleep } from "./app/utils/utils";
import { useRenewTokenMutation } from "./services/api";
const AuthContext = createContext();

async function handleConnectFromExtension({ renewToken, searchParams }) {
  await sleep(500); //wait for the contentscript of the extension to be loaded
  async function messageHandler(event) {
    if (event.data.type === "SYNCPULSE_TOKEN") {
      const extensionToken = event.data.token;
      if (extensionToken) {
        await renewToken({
          tokenToUseForRenew: extensionToken,
        });
      }
      window.removeEventListener("message", messageHandler);
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete("fromExtension");
      window.history.replaceState(
        null,
        "",
        `${location.pathname}?${newSearchParams.toString()}`
      );
    }
  }
  window.addEventListener("message", messageHandler);
  window.postMessage({ type: "GET_SYNCPULSE_TOKEN" }, "*");
}

export function AuthProvider({ children }) {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isConnectingFromExtension, setIsConnectingFromExtension] =
    useState(false);
  const [redirectTo, setRedirectTo] = useState(null);
  const [renewTokenMutation, renewTokenResult] = useRenewTokenMutation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isOpenedFromExtension = searchParams.get("fromExtension") == "true";

  useEffect(() => {
    async function tmp() {
      if (isAuthenticated) {
        //nothing to be done
      } else if (
        location.pathname.startsWith("/test") ||
        location.pathname.startsWith("/signup") ||
        location.pathname.startsWith("/password-reset") ||
        location.pathname.startsWith("/password-reset/request")
      ) {
        //no token renew needed
      } else if (isConnectingFromExtension || isApiPending(renewTokenResult)) {
      } else if (isOpenedFromExtension) {
        setIsConnectingFromExtension(true);
        await handleConnectFromExtension({
          renewToken,
          searchParams,
        });
        setIsConnectingFromExtension(false);
      } else if (token == null || token === "") {
        setRedirectTo(
          location.pathname === "/login" ? "/home" : location.pathname
        );
        navigate("/login");
      } else {
        await renewToken({ tokenToUseForRenew: null });
      }
    }
    tmp();
  }, [isAuthenticated, isOpenedFromExtension, token, renewTokenResult]);

  useEffect(() => {
    let timer;
    if (isAuthenticated) {
      timer = setInterval(() => {
        renewToken({ tokenToUseForRenew: null });
      }, 60 * 60 * 1000);
    }
    return () => clearInterval(timer);
  }, [isAuthenticated]);

  function logIn({ token }) {
    setToken(token);
    localStorage.setItem("token", token);
    setIsAuthenticated(true);
  }

  function logOut() {
    setToken(null);
    localStorage.removeItem("token");
    setIsAuthenticated(false);
  }

  async function renewToken({ tokenToUseForRenew }) {
    await renewTokenMutation({ tokenToUseForRenew })
      .unwrap()
      .then((res) => {
        logIn({ token: res });
      })
      .catch((err) => {
        logOut();
      });
  }
  return (
    <AuthContext.Provider
      value={{ token, isAuthenticated, logIn, logOut, redirectTo }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(AuthContext);
};
