import { Check, InfoOutlined } from "@mui/icons-material";
import {
  Alert,
  AspectRatio,
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Typography,
} from "@mui/joy";
import React, { useEffect, useState } from "react";
import { useSignupMutation } from "../../services/api";
import { isApiPending } from "../utils/utils";

const freeEmailDomains = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "outlook.com",
  "aol.com",
  "icloud.com",
];

const isWorkEmail = (email) => {
  const domain = email.split("@")[1];
  return !freeEmailDomains.includes(domain);
};

export default function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  //   const [captchaToken, setCaptchaToken] = useState(null);
  const [signupMutation, signupResult] = useSignupMutation();
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  //   const [captchaError, setCaptchaError] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [organizationNameError, setOrganizationNameError] = useState("");
  const isProduction = process.env.NODE_ENV === "production";
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const validatePassword = (password) => {
    const rules = [
      { regex: /[a-z]/, message: "at least one lowercase letter" },
      { regex: /[A-Z]/, message: "at least one uppercase letter" },
      { regex: /\d/, message: "at least one digit" },
      { regex: /[@$!%*?&]/, message: "at least one symbol" },
      { regex: /.{8,}/, message: "at least 8 characters long" },
    ];

    const unmetRules = rules.filter((rule) => !rule.regex.test(password));
    if (unmetRules.length === 0) {
      return "";
    }

    return `Password must contain: ${unmetRules
      .map((rule) => rule.message)
      .join(", ")}`;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (email === "") {
        setEmailError("");
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        setEmailError("Invalid email format");
      } else if (!isWorkEmail(email)) {
        setEmailError("Please use your work email");
      } else {
        setEmailError("");
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [email]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (password === "") {
        setPasswordError("");
      } else {
        const passwordValidationMessage = validatePassword(password);
        if (passwordValidationMessage) {
          setPasswordError(passwordValidationMessage);
        } else {
          setPasswordError("");
        }
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [password]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (confirmPassword === "") {
        setConfirmPasswordError("");
      } else if (password !== confirmPassword) {
        setConfirmPasswordError("Passwords do not match");
      } else {
        setConfirmPasswordError("");
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [confirmPassword, password]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (organizationName === "") {
        setOrganizationNameError("");
      } else if (!organizationName) {
        setOrganizationNameError("Organization name is required");
      } else {
        setOrganizationNameError("");
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [organizationName]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError("Invalid email format");
      valid = false;
    } else if (!isWorkEmail(email)) {
      setEmailError("Please use your work email");
      valid = false;
    } else {
      setEmailError("");
    }

    const passwordValidationMessage = validatePassword(password);
    if (passwordValidationMessage) {
      setPasswordError(passwordValidationMessage);
      console.log("passwordValidationMessage", passwordValidationMessage);
      valid = false;
    } else {
      setPasswordError("");
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      valid = false;
    } else {
      setConfirmPasswordError("");
    }

    // if (isProduction && !captchaToken) {
    //   setCaptchaError("You have been blocked - are you a bot ?");
    //   valid = false;
    // } else {
    //   setCaptchaError("");
    // }

    if (!organizationName) {
      setOrganizationNameError("Organization name is required");
      valid = false;
    } else {
      setOrganizationNameError("");
    }

    if (!valid) return;

    const result = await signupMutation({
      email,
      password,
      //   captchaToken,
      organizationName,
    });
    if (!result.error) {
      setShowSuccessAlert(true);
    }
  };

  return (
    <Container maxWidth="sm">
      {showSuccessAlert ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Alert
            size="lg"
            color="success"
            variant="solid"
            invertedColors
            startDecorator={
              <AspectRatio
                variant="solid"
                ratio="1"
                sx={{
                  minWidth: 40,
                  borderRadius: "50%",
                  boxShadow: "0 2px 12px 0 rgb(0 0 0/0.2)",
                }}
              >
                <div>
                  <Check fontSize="medium" />
                </div>
              </AspectRatio>
            }
            sx={{ alignItems: "flex-start", overflow: "hidden" }}
          >
            <Box sx={{ pl: 2, pr: 4 }}>
              <Typography level="title-lg">Success</Typography>
              <Typography level="body-sm">
                User {email} is created on Syncpulse
              </Typography>
              <Typography level="body-sm">
                <i>You can now login into the extension!</i>
              </Typography>
            </Box>
          </Alert>
        </Box>
      ) : (
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ height: "100vh" }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <img
            src="/syncpulse_2048.png"
            alt="SyncPulse Logo"
            style={{
              display: "block",
              margin: "0 auto",
              width: "60px",
              height: "60px",
            }}
          />
          <Typography
            level="h1"
            gutterBottom
            color="primary"
            mt={4}
            sx={{ textAlign: "center" }}
          >
            Welcome to SyncPulse!
          </Typography>
          <FormControl
            error={!!emailError}
            sx={{ mt: 4, textAlign: "center", width: "380px" }}
          >
            <FormLabel>
              <Typography variant="soft">Work email</Typography>
            </FormLabel>
            <Input
              type="text"
              fullWidth
              variant="plain"
              color="primary"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailError && (
              <FormHelperText>
                <InfoOutlined />
                {emailError}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            error={!!organizationNameError}
            sx={{ mt: 2, textAlign: "center", width: "380px" }}
          >
            <FormLabel>
              <Typography variant="soft">Organization Name</Typography>
            </FormLabel>
            <Input
              type="text"
              variant="plain"
              color="primary"
              fullWidth
              value={organizationName}
              onChange={(e) => setOrganizationName(e.target.value)}
            />
            {organizationNameError && (
              <FormHelperText>
                <InfoOutlined />
                {organizationNameError}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            error={!!passwordError}
            sx={{ mt: 2, textAlign: "center", width: "380px" }}
          >
            <FormLabel>
              <Typography variant="soft">Password</Typography>
            </FormLabel>
            <Input
              type="password"
              variant="plain"
              color="primary"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {passwordError && (
              <FormHelperText>
                <InfoOutlined />
                {passwordError}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            error={!!confirmPasswordError}
            sx={{ mt: 2, textAlign: "center", width: "380px" }}
          >
            <FormLabel>
              <Typography variant="soft">Confirm Password</Typography>
            </FormLabel>
            <Input
              type="password"
              variant="plain"
              color="primary"
              fullWidth
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {confirmPasswordError && (
              <FormHelperText>
                <InfoOutlined />
                {confirmPasswordError}
              </FormHelperText>
            )}
          </FormControl>
          {/* {isProduction && (
            <Box sx={{ mt: 2 }}>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={(token) => setCaptchaToken(token)}
              />
            </Box>
          )} */}
          <Button
            type="submit"
            variant="solid"
            color="primary"
            sx={{ mt: 4 }}
            disabled={
              !email || !password || !confirmPassword || !organizationName
              //   (isProduction && !captchaToken)
            }
            loading={isApiPending(signupResult)}
          >
            Signup
          </Button>
          {/* {captchaError ? (
            <Alert color="danger" variant="soft" sx={{ mt: 4 }}>
              <Stack direction="column">
                <Box>ReCAPTCHA error, are your a bot ?</Box>
                <Box>{captchaError}</Box>
              </Stack>
            </Alert>
          ) : */}
          {signupResult.error?.data?.message ? (
            <Alert color="danger" variant="soft" sx={{ mt: 4 }}>
              {signupResult.error?.data?.message}
            </Alert>
          ) : (
            <Box mt={4} height={"45px"}></Box>
          )}
        </Box>
      )}
    </Container>
  );
}
