import { Box, Sheet, Typography } from "@mui/joy";
import React from "react";

export default function NavBar() {
  return (
    <Box width="52px" minWidth="52px" maxWidth="52px" height="100vh">
      <Sheet
        color="primary"
        variant="solid"
        sx={{ width: "100%", height: "100%" }}
      >
        <Box width="100%" height="100%" textAlign="center" pt={1}>
          <Typography level="h1" sx={{ color: "white" }}>
            S
          </Typography>
        </Box>
      </Sheet>
    </Box>
  );
}
