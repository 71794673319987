import { AccountCircleOutlined } from "@mui/icons-material";
import { Box, IconButton, Link, Stack, Typography } from "@mui/joy";
import { styled } from "@mui/system";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

const CustomLink = styled(Link)({
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
});

//use breadcrumbs
export default function Header() {
  const pathHierarchy = [
    { path: "/home", name: "Home" },
    { path: `/workspace/679T6`, name: "Workspace" },
  ];
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Box pl={1} pt={0.3} pb={0.3} typography="body-xs" color="neutral.500">
        <Stack direction="row" alignItems="center">
          {pathHierarchy.map((item, index) => (
            <React.Fragment key={item.path}>
              <CustomLink to={item.path} component={RouterLink}>
                <Typography level="body-xs">{item.name}</Typography>
              </CustomLink>
              {index < pathHierarchy.length - 1 && (
                <Typography level="body-xs" mx={0.5}>
                  {"->"}
                </Typography>
              )}
            </React.Fragment>
          ))}
        </Stack>
      </Box>
      <IconButton color="primary" sx={{ minHeight: "8px" }}>
        <AccountCircleOutlined sx={{ fontSize: 20 }} />
      </IconButton>
    </Stack>
  );
}
