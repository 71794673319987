import { Check, InfoOutlined } from "@mui/icons-material";
import {
  Alert,
  AspectRatio,
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Typography,
} from "@mui/joy";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { usePasswordResetMutation } from "../../services/api";
import { isApiPending } from "../utils/utils";

export default function PasswordReset() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const emailParam = searchParams.get("email") || "";
  const [email, setEmail] = useState(emailParam);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordReset, passwordResetResult] = usePasswordResetMutation();
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const validatePassword = (password) => {
    const rules = [
      { regex: /[a-z]/, message: "at least one lowercase letter" },
      { regex: /[A-Z]/, message: "at least one uppercase letter" },
      { regex: /\d/, message: "at least one digit" },
      { regex: /[@$!%*?&]/, message: "at least one symbol" },
      { regex: /.{8,}/, message: "at least 8 characters long" },
    ];

    const unmetRules = rules.filter((rule) => !rule.regex.test(password));
    if (unmetRules.length === 0) {
      return "";
    }

    return `Password must contain: ${unmetRules
      .map((rule) => rule.message)
      .join(", ")}`;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (password === "") {
        setPasswordError("");
      } else {
        const passwordValidationMessage = validatePassword(password);
        if (passwordValidationMessage) {
          setPasswordError(passwordValidationMessage);
        } else {
          setPasswordError("");
        }
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [password]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (confirmPassword === "") {
        setConfirmPasswordError("");
      } else if (password !== confirmPassword) {
        setConfirmPasswordError("Passwords do not match");
      } else {
        setConfirmPasswordError("");
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [confirmPassword, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;

    const passwordValidationMessage = validatePassword(password);
    if (passwordValidationMessage) {
      setPasswordError(passwordValidationMessage);
      valid = false;
    } else {
      setPasswordError("");
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      valid = false;
    } else {
      setConfirmPasswordError("");
    }

    if (!valid) return;

    const result = await passwordReset({
      magic_token: token,
      password,
    });
    if (!result.error) {
      setShowSuccessAlert(true);
    }
  };

  return (
    <Container maxWidth="sm">
      {showSuccessAlert ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Alert
            size="lg"
            color="success"
            variant="solid"
            invertedColors
            startDecorator={
              <AspectRatio
                variant="solid"
                ratio="1"
                sx={{
                  minWidth: 40,
                  borderRadius: "50%",
                  boxShadow: "0 2px 12px 0 rgb(0 0 0/0.2)",
                }}
              >
                <div>
                  <Check fontSize="medium" />
                </div>
              </AspectRatio>
            }
            sx={{ alignItems: "flex-start", overflow: "hidden" }}
          >
            <Box sx={{ pl: 2, pr: 4 }}>
              <Typography level="title-lg">Success</Typography>
              <Typography level="body-sm">
                Password has been reset successfully
              </Typography>
              <Typography level="body-sm">
                <i>You can now login with your new password!</i>
              </Typography>
            </Box>
          </Alert>
        </Box>
      ) : (
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ height: "100vh" }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <img
            src="/syncpulse_2048.png"
            alt="SyncPulse Logo"
            style={{
              display: "block",
              margin: "0 auto",
              width: "60px",
              height: "60px",
            }}
          />
          <Typography
            level="h1"
            gutterBottom
            color="primary"
            mt={4}
            sx={{ textAlign: "center" }}
          >
            Reset Your Password
          </Typography>
          <FormControl
            error={!!passwordError}
            sx={{ mt: 4, textAlign: "center", width: "380px" }}
          >
            <FormLabel>
              <Typography variant="soft">New Password</Typography>
            </FormLabel>
            <Input
              type="password"
              fullWidth
              variant="plain"
              color="primary"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {passwordError && (
              <FormHelperText>
                <InfoOutlined />
                {passwordError}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            error={!!confirmPasswordError}
            sx={{ mt: 2, textAlign: "center", width: "380px" }}
          >
            <FormLabel>
              <Typography variant="soft">Confirm New Password</Typography>
            </FormLabel>
            <Input
              type="password"
              variant="plain"
              color="primary"
              fullWidth
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {confirmPasswordError && (
              <FormHelperText>
                <InfoOutlined />
                {confirmPasswordError}
              </FormHelperText>
            )}
          </FormControl>
          <Button
            type="submit"
            variant="solid"
            color="primary"
            sx={{ mt: 4 }}
            disabled={!password || !confirmPassword}
            loading={isApiPending(passwordResetResult)}
          >
            Reset Password
          </Button>
          {passwordResetResult.error?.data?.message && (
            <Alert color="danger" variant="soft" sx={{ mt: 4 }}>
              {passwordResetResult.error?.data?.message}
            </Alert>
          )}
        </Box>
      )}
      <Box height="60px"></Box>
    </Container>
  );
}
