import { CssVarsProvider as JoyCssVarsProvider, Sheet } from "@mui/joy";
import { extendTheme } from "@mui/joy/styles";
import {
  THEME_ID as MATERIAL_THEME_ID,
  createTheme as materialCreateTheme,
  ThemeProvider as MaterialCssVarsProvider,
} from "@mui/material";
import { default as React } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { App } from "./App";
import { AuthProvider } from "./AuthProvider";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import store from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));

// declare module "@mui/joy/styles" {
//   // No custom tokens found, you can skip the theme augmentation.
// }

// primary 500 #8700E0

const joyMuiTheme = extendTheme({
  // fontFamily: {
  //   display: "Lexend", // applies to `h1`–`h4`
  //   body: "Inter", // applies to `title-*` and `body-*`
  // },
  fontWeight: {
    xs: 200,
    sm: 200,
    md: 200,
    lg: 500,
    xl: 600,
  },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          50: "#faf5ff",
          100: "#f3e8ff",
          200: "#e9d5ff",
          300: "#d8b4fe",
          400: "#c084fc",
          500: "#8700E0",
          600: "#7300be",
          700: "#6500a8",
          800: "#570091",
          900: "#480078",
          // 50: "#c266ff",
          // 100: "#b84dff",
          // 200: "#ad33ff",
          // 300: "#9900ff",
          // 400: "#7a00cc",
          // 500: "#6500a8",
          // 600: "#5c0099",
          // 700: "#4c0080",
          // 800: "#3d0066",
          // 900: "#2e004d",
        },
        neutral: {
          plainColor: "var(--joy-palette-neutral-500)",
          outlinedColor: "var(--joy-palette-neutral-500)",
        },
      },
    },
    dark: {
      palette: {},
    },
  },
});

// export default theme;

const muiTheme = materialCreateTheme({
  palette: {
    primary: {
      main: joyMuiTheme.colorSchemes.light.palette.primary[500],
      light: joyMuiTheme.colorSchemes.light.palette.primary[300],
      dark: joyMuiTheme.colorSchemes.light.palette.primary[700],
    },
  },
});

root.render(
  // <React.StrictMode>
  <Router>
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: muiTheme }}>
      <JoyCssVarsProvider theme={joyMuiTheme}>
        <Provider store={store}>
          <AuthProvider>
            <Sheet variant="plain">
              <App />
            </Sheet>
          </AuthProvider>
        </Provider>
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  </Router>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
