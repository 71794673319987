import { Add, Close, DeleteOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Modal,
  ModalDialog,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Textarea,
  Typography,
} from "@mui/joy";
import React, { useState } from "react";
import FileDisplay from "./FileDisplay";

export default function GroupData({ data, setData, writeMode = false }) {
  const files = data.files ?? [];
  const [uploadFileModalOpen, setUploadFileModalOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);

  return (
    <>
      <Modal
        open={uploadFileModalOpen}
        onClose={() => {
          setUploadFileModalOpen(false);
          setFile(null);
        }}
      >
        <ModalDialog sx={{ pt: 1 }} minWidth="500px">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography level="h4" color="neutral">
              Upload File
            </Typography>
            <IconButton
              onClick={() => {
                setUploadFileModalOpen(false);
                setFile(null);
              }}
            >
              <Close />
            </IconButton>
          </Stack>
          <Box component="form" sx={{ mt: 2 }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Button variant="outlined" component="label" color="neutral">
                {file ? "Change File" : "Upload File"}
                <input
                  type="file"
                  hidden
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Button>
              <Typography level="body-xs">
                {file?.name ?? "No file selected"}
              </Typography>
            </Stack>
            <FormControl sx={{ mt: 2 }}>
              <FormLabel>
                <Typography level="title-md" color="neutral">
                  Description
                </Typography>
              </FormLabel>
              <Textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Type a description as precise as possible of the file you upload. This description will be used by AI engine to generate the code."
                minRows={5}
                size="sm"
              />
            </FormControl>
            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              sx={{ mt: 4 }}
            >
              <Button
                onClick={() => setUploadFileModalOpen(false)}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                disabled={!(file && description)}
                onClick={() =>
                  handleAddFile({
                    file,
                    data,
                    setData,
                    setUploadFileModalOpen,
                    files,
                  })
                }
              >
                Validate
              </Button>
            </Stack>
          </Box>
        </ModalDialog>
      </Modal>
      <Box height="100%">
        <Tabs variant="plain" defaultValue="sftp" sx={{ height: "100%" }}>
          <TabList sx={{ minHeight: "20px", height: "20px" }}>
            {writeMode && (
              <IconButton
                onClick={() => setUploadFileModalOpen(true)}
                sx={{ minHeight: "4px", minWidth: "4px" }}
              >
                <Add sx={{ height: "10px", width: "10px", pt: 0.15 }} />
              </IconButton>
            )}
            <Divider orientation="vertical" />
            {files.map((file) => (
              <Tab
                disableIndicator
                value={file.id}
                key={file.id}
                sx={{ p: 0, pr: 0.5, pl: 0.5, minBlockSize: "20px" }}
              >
                <Stack direction="row" alignItems="center">
                  <Typography
                    level="body-xs"
                    sx={{
                      maxWidth: "100px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    title={file.name}
                  >
                    {file.name}
                  </Typography>
                  {writeMode && (
                    <IconButton
                      onClick={() =>
                        handleDeleteFile({
                          fileId: file.id,
                          data,
                          setData,
                          files,
                        })
                      }
                      sx={{ minHeight: "4px", minWidth: "4px", p: 0 }}
                    >
                      <DeleteOutlined
                        fontSize="small"
                        sx={{ fontSize: "12px" }}
                      />
                    </IconButton>
                  )}
                </Stack>
              </Tab>
            ))}
          </TabList>
          {files.map((file) => (
            <TabPanel
              value={file.id}
              sx={{ p: 0, height: "100%" }}
              key={file.id}
            >
              <FileDisplay file={file} writeMode={writeMode} />
            </TabPanel>
          ))}
        </Tabs>
      </Box>
    </>
  );
}

function handleAddFile({ file, data, setData, setUploadFileModalOpen, files }) {
  const uuid = crypto.randomUUID();
  const reader = new FileReader();

  reader.onload = (e) => {
    const fileContent = e.target.result;
    setData({
      ...data,
      files: [
        ...files,
        { id: uuid, name: file.name, type: file.type, content: fileContent },
      ],
    });
  };

  if (file.type.startsWith("text/")) {
    reader.readAsText(file);
  } else if (file.type.startsWith("image/")) {
    reader.readAsDataURL(file);
  } else if (file.type.startsWith("audio/")) {
    reader.readAsDataURL(file);
  } else if (file.type.startsWith("video/")) {
    reader.readAsDataURL(file);
  } else {
    reader.readAsArrayBuffer(file);
  }
  setUploadFileModalOpen(false);
}

function handleDeleteFile({ fileId, data, files, setData }) {
  setData({
    ...data,
    files: files.filter((file) => file.id !== fileId),
  });
}
