import { Stack } from "@mui/joy";
import React from "react";
import Flows from "../flows/Flows";
import Notes from "../home/Notes";

export default function Home() {
  return (
    <Stack direction="column" spacing={3}>
      <Notes />
      <Flows />
    </Stack>
  );
}
